import React from "react";
import Icon, { IconProps } from "./Icon";

export default function UserEducation({
  viewBox = "0 0 16 13",
  width = 16,
  height = 12,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox} width={width} height={height}>
      <path
        d="M8 3.11765C8 1.9481 8.9402 1 10.1 1H15V10.8824H10.1C8.9402 10.8824 8 11.8305 8 13M8 3.11765C8 1.9481 7.0598 1 5.9 1H1V10.8824H5.9C7.0598 10.8824 8 11.8305 8 13M8 3.11765V13"
        stroke="url(#paint0_linear_433_24649)"
        stroke-linecap="round"
        fill="none"
      />
      <defs>
        <linearGradient
          id="paint0_linear_433_24649"
          x1="13.9792"
          y1="2.125"
          x2="3.52475"
          y2="13.741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AB7DE3" />
          <stop offset="1" stop-color="#ADFFE2" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
