import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Analysis({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M17.5538 6.95568C16.9636 4.76309 15.237 3.03641 13.0444 2.44626C12.8168 2.38501 12.703 2.35439 12.5903 2.3877C12.4981 2.41492 12.4033 2.48755 12.3531 2.56947C12.2916 2.66971 12.2916 2.8 12.2916 3.06058V7.04176C12.2916 7.27512 12.2916 7.3918 12.337 7.48093C12.377 7.55933 12.4407 7.62307 12.5191 7.66302C12.6083 7.70843 12.7249 7.70843 12.9583 7.70843H16.9395C17.2001 7.70843 17.3303 7.70843 17.4306 7.64696C17.5125 7.59672 17.5851 7.50195 17.6124 7.40979C17.6457 7.29702 17.615 7.18324 17.5538 6.95568Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0416 10.8334C16.0416 14.6304 12.9636 17.7084 9.16663 17.7084C5.36967 17.7084 2.29163 14.6304 2.29163 10.8334C2.29163 7.03647 5.36967 3.95843 9.16663 3.95843C9.23748 3.95843 9.30808 3.9595 9.37841 3.96163V9.29195C9.37841 9.75866 9.37841 9.99202 9.46924 10.1703C9.54914 10.3271 9.67662 10.4546 9.83342 10.5345C10.0117 10.6253 10.245 10.6253 10.7117 10.6253H16.0385C16.0406 10.6944 16.0416 10.7638 16.0416 10.8334Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
