import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Burn2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M12.3999 9.24929C12.6508 9.49205 12.9376 9.49402 13.0086 9.49402C13.0087 9.49402 13.0089 9.49402 13.0093 9.49403C13.0199 9.49426 13.1395 9.49685 13.2736 9.4536C13.4024 9.41206 13.5455 9.32918 13.6571 9.1896C14.0605 8.75766 14.3524 8.30975 14.5457 7.8454C15.0578 8.32872 15.478 8.91301 15.779 9.55019C16.0992 10.2446 16.264 10.9913 16.249 11.747L16.2487 11.7624L16.249 11.7777C16.2639 12.4872 16.1145 13.2033 15.8094 13.8823L15.8086 13.884C15.5165 14.5385 15.1015 15.1337 14.578 15.6402C14.0583 16.143 13.428 16.5453 12.7499 16.8137L12.7476 16.8146C12.0327 17.1003 11.2733 17.25 10.4994 17.25C9.72555 17.25 8.96608 17.1003 8.25116 16.8146L8.25118 16.8146L8.24353 16.8116C7.55648 16.5457 6.94401 16.1464 6.42081 15.6402L5.89929 16.1792L6.42081 15.6402C5.8981 15.1344 5.48349 14.5402 5.19147 13.8869C4.90244 13.2234 4.75 12.5004 4.75 11.7619C4.75 10.9194 4.94846 10.0913 5.33118 9.33586C5.71442 8.57939 6.26448 7.93045 6.93223 7.44588L6.93229 7.44597L6.94271 7.43813C7.30616 7.16461 7.64093 6.83927 7.93125 6.50218L7.93128 6.50221L7.93664 6.49585C8.37606 5.97404 8.69369 5.41552 8.92901 4.82835C9.12898 4.34263 9.21087 3.78929 9.20124 3.21031C10.0441 3.73927 10.7265 4.46358 11.238 5.38273C11.9252 6.61745 12.1957 7.68682 12.1381 8.57765L12.1366 8.6018V8.626C12.1366 8.87999 12.2492 9.10346 12.3999 9.24929ZM12.3999 9.24929L12.9214 8.7103M12.3999 9.24929C12.3999 9.2493 12.3999 9.2493 12.3999 9.2493L12.9214 8.7103M12.9214 8.7103C12.904 8.69344 12.8866 8.65972 12.8866 8.626L13.1416 8.64247C13.1209 8.66508 13.0998 8.68769 13.0783 8.7103C13.0608 8.74402 13.026 8.74402 13.0086 8.74402C12.9911 8.74402 12.9563 8.74402 12.9214 8.7103Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </Icon>
  );
}
