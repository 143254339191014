import React from "react";
import Icon, { IconProps } from "./Icon";

export default function CheckList2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M9.79167 13.5417H16.875M9.79167 6.45833H16.875M3.125 7.1875L4.51389 8.125L7.29167 4.375M3.125 14.6875L4.51389 15.625L7.29167 11.875"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
