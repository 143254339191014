import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Settings2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M7.12657 4.47473L5.63266 4.12998C5.3527 4.06537 5.05919 4.14955 4.85602 4.35272L4.35296 4.85578C4.14979 5.05895 4.06562 5.35245 4.13022 5.63242L4.47497 7.12633C4.55292 7.46408 4.41364 7.81481 4.12523 8.00709L2.66308 8.98186C2.43124 9.13641 2.29199 9.39661 2.29199 9.67523V10.3249C2.29199 10.6036 2.43124 10.8637 2.66308 11.0183L4.12523 11.9931C4.41364 12.1853 4.55292 12.5361 4.47497 12.8738L4.13022 14.3677C4.06562 14.6477 4.14979 14.9412 4.35296 15.1444L4.85602 15.6474C5.05919 15.8506 5.3527 15.9348 5.63266 15.8702L7.12658 15.5254C7.46433 15.4475 7.81506 15.5868 8.00733 15.8752L8.9821 17.3373C9.13666 17.5692 9.39685 17.7084 9.67548 17.7084H10.3252C10.6038 17.7084 10.864 17.5692 11.0185 17.3373L11.9933 15.8752C12.1856 15.5868 12.5363 15.4475 12.8741 15.5254L14.368 15.8702C14.648 15.9348 14.9415 15.8506 15.1446 15.6474L15.6477 15.1444C15.8509 14.9412 15.935 14.6477 15.8704 14.3677L15.5257 12.8738C15.4477 12.5361 15.587 12.1853 15.8754 11.9931L17.3376 11.0183C17.5694 10.8637 17.7087 10.6036 17.7087 10.3249V9.67523C17.7087 9.39661 17.5694 9.13641 17.3376 8.98186L15.8754 8.00709C15.587 7.81481 15.4477 7.46408 15.5257 7.12633L15.8704 5.63242C15.935 5.35245 15.8509 5.05895 15.6477 4.85578L15.1446 4.35272C14.9415 4.14955 14.648 4.06537 14.368 4.12998L12.8741 4.47473C12.5363 4.55267 12.1856 4.4134 11.9933 4.12499L11.0185 2.66283C10.864 2.431 10.6038 2.29175 10.3252 2.29175H9.67548C9.39685 2.29175 9.13666 2.431 8.9821 2.66283L8.00733 4.12499C7.81506 4.4134 7.46433 4.55267 7.12657 4.47473Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M12.292 10.0001C12.292 11.2657 11.266 12.2917 10.0003 12.2917C8.73467 12.2917 7.70866 11.2657 7.70866 10.0001C7.70866 8.73443 8.73467 7.70841 10.0003 7.70841C11.266 7.70841 12.292 8.73443 12.292 10.0001Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
