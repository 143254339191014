import React from "react";
import Icon, { IconProps } from "./Icon";

export default function BarChart({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M3.95833 11.2728C3.4981 11.2728 3.125 11.6459 3.125 12.1061V16.0413C3.125 16.5015 3.4981 16.8746 3.95833 16.8746H5.48333C5.94357 16.8746 6.31667 16.5015 6.31667 16.0413V12.1061C6.31667 11.6459 5.94357 11.2728 5.48333 11.2728H3.95833Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M9.23335 7.56999C8.77311 7.56999 8.40001 7.94308 8.40001 8.40332V16.0422C8.40001 16.5024 8.77311 16.8755 9.23335 16.8755H10.7583C11.2186 16.8755 11.5917 16.5024 11.5917 16.0422V8.40332C11.5917 7.94308 11.2186 7.56999 10.7583 7.56999H9.23335Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.5167 3.125C14.0564 3.125 13.6833 3.4981 13.6833 3.95833V16.0417C13.6833 16.5019 14.0564 16.875 14.5167 16.875H16.0417C16.5019 16.875 16.875 16.5019 16.875 16.0417V3.95833C16.875 3.4981 16.5019 3.125 16.0417 3.125H14.5167Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
