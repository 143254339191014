import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Search2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M16.875 16.875L13.4388 13.4388M13.4388 13.4388C14.5321 12.3454 15.2083 10.835 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C10.835 15.2083 12.3454 14.5321 13.4388 13.4388Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
