import React from "react";
import Icon, { IconProps } from "./Icon";

export default function PlugOutline({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2164 3.31667C13.2164 2.88281 12.8336 2.5 12.3997 2.5C11.9403 2.5 11.583 2.88281 11.583 3.31667L11.583 5.8145H8.31634V3.31667C8.31634 2.88281 7.93352 2.5 7.49967 2.5C7.04029 2.5 6.683 2.88281 6.683 3.31667V5.8145H5.45809C4.49714 5.8145 3.81445 6.62181 3.81445 7.45814V8.27481C3.81445 8.889 4.14636 9.42143 4.64169 9.70364C4.77191 11.6816 6.09269 13.4823 7.84704 14.2676C7.86656 14.2763 7.88381 14.2865 7.89781 14.2967V16.2499C7.89781 17.0702 8.56281 17.7352 9.38312 17.7352H10.5165C11.3368 17.7352 12.0018 17.0702 12.0018 16.2499V14.2934C12.0165 14.2824 12.0349 14.2712 12.0558 14.2617C13.7956 13.4707 15.1095 11.6676 15.2553 9.69676C15.7341 9.42103 16.0851 8.9084 16.0851 8.27481V7.45814C16.0851 6.9543 15.8456 6.55385 15.5957 6.30392C15.3458 6.05399 14.9453 5.8145 14.4415 5.8145H13.2164V3.31667ZM11.5445 13.1372C12.9573 12.4949 14.0115 10.9667 14.0331 9.38318L14.0331 9.09148C14.0331 8.86596 14.216 8.68315 14.4415 8.68315C14.6456 8.68315 14.8498 8.5045 14.8498 8.27481V7.45814C14.8498 7.25397 14.6456 7.0498 14.4415 7.0498H5.45809C5.2284 7.0498 5.04976 7.25397 5.04976 7.45814V8.27481C5.04976 8.5045 5.2284 8.68315 5.45809 8.68315C5.68361 8.68315 5.86643 8.86596 5.86643 9.09148L5.86643 9.38318C5.86643 10.969 6.92049 12.4994 8.35172 13.1401C8.78774 13.3352 9.13312 13.7343 9.13312 14.212V16.2499C9.13312 16.3879 9.24505 16.4999 9.38312 16.4999H10.5165C10.6545 16.4999 10.7665 16.3879 10.7665 16.2499V14.2098C10.7665 13.7331 11.1105 13.3345 11.5445 13.1372Z"
        fill={fill}
      />
    </Icon>
  );
}
